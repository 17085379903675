import axios from 'axios';

import {
  FETCH_TASKS_START,
  FETCH_TASKS_SUCCESS,
  FETCH_TASKS_FAIL,
  FETCH_TASK_START,
  FETCH_TASK_SUCCESS,
  FETCH_TASK_FAIL,
  FETCH_LATEST_TASKS_START,
  FETCH_LATEST_TASKS_SUCCESS,
  FETCH_LATEST_TASKS_FAIL,
  FETCH_SDL_FOLDERS_START,
  FETCH_SDL_FOLDERS_SUCCESS,
  FETCH_SDL_FOLDERS_FAIL,
  FETCH_GITHUB_REPORISORIES_START,
  FETCH_GITHUB_REPORISORIES_SUCCESS,
  FETCH_GITHUB_REPORISORIES_FAIL,
  FETCH_GITHUB_BRANCHES_START,
  FETCH_GITHUB_BRANCHES_SUCCESS,
  FETCH_GITHUB_BRANCHES_FAIL,
} from '../actionTypes';

import {
  XSLT_CONVERSION_TYPES_API_URL,
  TASKS_API_URL,
  PYTHON_CONVERSION_TYPES_API_URL,
  ENABLE_OXYGEN_CONVERSIONS,
} from '../../config';
import {
  actionFail,
  // showErrorSnackbar
} from './appActions';

import {
  ENTITY_TYPE_TASK,
  ENTITY_TYPE_TASK_STATE,
  ENTITY_TYPE_PROGRESS,
  TASK_ENGINE_PYTHON,
  TASK_ENGINE_OXYGEN,
  TASK_ENGINE_XSLT,
  TASK_STATUS_INTERRUPTED,
  TASK_CONVERTER_NORMALIZATION,
  ConversionTypeEntity,
  ConversionTagEntity,
} from '../../core/entities';

import {
  parseConversionTagEntity,
  parseConversionTypeEntity,
  parseEventEntity,
  parseProgressEntity,
  parseTaskEntity,
  parseIxiasoftDrmOjectEntity,
  parseIxiasoftUserEntity,
  parseIxiasoftStatusEntity,
  parseIxiasoftObjectTypeEntity,
  parsePaligoPublishSettingEntity,
  parsePaligoDocumentEntity,
  parseTaskStateEntity,
} from '../../core/parsers';

const TASKS_URL = `${TASKS_API_URL}/tasks`;
const LATEST_TASKS_URL = `${TASKS_URL}/latest`;
const NORMALIZATION_URL = `${TASKS_API_URL}/normalization`;
const PANDOC_URL = `${TASKS_API_URL}/pandoc`;

const GITHUB_UPLOAD_URL = `${TASKS_API_URL}/github/upload`;
const GITHUB_DOWNLOAD_URL = `${TASKS_API_URL}/github/download`;

const ZOOMIN_EXPORT_URL = `${TASKS_API_URL}/zoomin-to-fluid-topics/export`;
const ZOOMIN_UPLOAD_URL = `${TASKS_API_URL}/zoomin/upload`;

const GITHUB_REPOSITORIES_URL = `${TASKS_API_URL}/github/repository`;

const FLUID_TOPICS_PUBLISH_URL = `${TASKS_API_URL}/fluid-topics/import`;

const IXIASOFT_IMPORT_URL = `${TASKS_API_URL}/ixiasoft/import`;
const IXIASOFT_EXPORT_URL = `${TASKS_API_URL}/ixiasoft/export`;

const PALIGO_PUBLISH_URL = `${TASKS_API_URL}/paligo/publish`;
const PALIGO_IMPORT_URL = `${TASKS_API_URL}/paligo/import`;

const SDL_EXPORT_URL = `${TASKS_API_URL}/sdl/export`;
const SDL_IMPORT_URL = `${TASKS_API_URL}/sdl/import`;

const SDL_FOLDER_URL = `${TASKS_API_URL}/sdl/folders`;

const KION_SDL_EXPORT_URL = `${TASKS_API_URL}/kion/sdl/export`;
const KION_SDL_IMPORT_URL = `${TASKS_API_URL}/kion/sdl/import`;

export function fetchLatestTasks() {
  return function (dispatch) {
    dispatch({ type: FETCH_LATEST_TASKS_START });

    return axios.get(LATEST_TASKS_URL)
      .then(res => {
        const payload = res.data.data;
        dispatch({ type: FETCH_LATEST_TASKS_SUCCESS, payload });
      })
      .catch(error => {
        dispatch(actionFail(FETCH_LATEST_TASKS_FAIL, error.response));
      });
  };
}

export function fetchTasks() {
  return function (dispatch) {
    dispatch({ type: FETCH_TASKS_START });

    return axios.get(TASKS_URL)
      .then(res => {
        const payload = res.data.data;
        dispatch({ type: FETCH_TASKS_SUCCESS, payload });
      })
      .catch(error => {
        dispatch(actionFail(FETCH_TASKS_FAIL, error.response));
      });
  };
}

export function fetchTask(taskId) {
  return function (dispatch) {
    dispatch({ type: FETCH_TASK_START });

    return axios.get(`${TASKS_URL}/${taskId}`)
      .then(res => {
        const payload = res.data.data;
        dispatch({ type: FETCH_TASK_SUCCESS, payload });
      })
      .catch(error => {
        dispatch(actionFail(FETCH_TASK_FAIL, error.response));
      });
  };
}

export function fetchTaskLogs(taskId, token, limit) {
  let queryParams = '';
  if (token) {
    queryParams += `token=${token}`;
  }
  if (limit) {
    queryParams += `${queryParams ? '&' : ''}limit=${limit}`;
  }

  return function (dispatch) {
    return axios.get(`${TASKS_API_URL}/logs/${taskId}${queryParams ? `?${queryParams}` : ''}`)
      .then(res => ({
        events: res.data.data.map(parseEventEntity),
        nextForwardToken: res.headers['x-next-forward-token'],
        nextBackwardToken: res.headers['x-next-backward-token'],
      }))
      .catch(error => {
        dispatch(actionFail(null, error.response));

        return Promise.reject(error.response);
      });
  };
}

export function getTaskLogs(taskId, token, limit) {
  let queryParams = '';
  if (token) {
    queryParams += `token=${token}`;
  }
  if (limit) {
    queryParams += `${queryParams ? '&' : ''}limit=${limit}`;
  }

  return axios.get(`${TASKS_API_URL}/logs/${taskId}${queryParams ? `?${queryParams}` : ''}`)
    .then(res => ({
      events: res.data.data.map(parseEventEntity),
      nextForwardToken: res.headers['x-next-forward-token'],
      nextBackwardToken: res.headers['x-next-backward-token'],
    }))
    .catch(error => error.response);
}

export function fetchTaskErrors(taskId, token, limit) {
  let queryParams = '';
  if (token) {
    queryParams += `token=${token}`;
  }
  if (limit) {
    queryParams += `${queryParams ? '&' : ''}limit=${limit}`;
  }

  return function (dispatch) {
    return axios.get(`${TASKS_API_URL}/tasks/${taskId}/errors${queryParams ? `?${queryParams}` : ''}`)
      .then(res => ({
        events: res.data.data.map(parseEventEntity),
        nextForwardToken: res.headers['x-next-forward-token'],
        nextBackwardToken: res.headers['x-next-backward-token'],
      }))
      .catch(error => {
        dispatch(actionFail(null, error.response));

        return Promise.reject(error.response);
      });
  };
}

export function fetchTaskWarnings(taskId, token, limit) {
  let queryParams = '';
  if (token) {
    queryParams += `token=${token}`;
  }
  if (limit) {
    queryParams += `${queryParams ? '&' : ''}limit=${limit}`;
  }

  return function (dispatch) {
    return axios.get(`${TASKS_API_URL}/tasks/${taskId}/warnings${queryParams ? `?${queryParams}` : ''}`)
      .then(res => ({
        events: res.data.data.map(parseEventEntity),
        nextForwardToken: res.headers['x-next-forward-token'],
        nextBackwardToken: res.headers['x-next-backward-token'],
      }))
      .catch(error => {
        dispatch(actionFail(null, error.response));

        return Promise.reject(error.response);
      });
  };
}

export function fetchTaskReportLogs(taskId, filterPattern, token, limit = 100) {
  let queryParams = '';
  if (filterPattern) {
    queryParams += `filter_pattern=${filterPattern}`;
  }
  if (token) {
    queryParams += `${queryParams ? '&' : ''}token=${token}`;
  }
  if (limit) {
    queryParams += `${queryParams ? '&' : ''}limit=${limit}`;
  }

  return function () {
    return axios.get(`${TASKS_API_URL}/report_logs/${taskId}${queryParams ? `?${queryParams}` : ''}`)
      .then(res => ({
        events: res.data.data.map(parseEventEntity),
        nextForwardToken: res.headers['x-next-forward-token'],
      }))
      .catch(error => Promise.reject(error.response));
  };
}

export function createTask(task, env) {
  return function (dispatch) {
    const body = {
      data: {
        type: ENTITY_TYPE_TASK,
        attributes: task,
      },
    };

    return axios.post(TASKS_URL, body, { params: env })
      .then(res => res.data.data)
      .catch(error => {
        dispatch(actionFail(null, error.response));
        return Promise.reject();
      });
  };
}

export function createNormalizationTask(taskAttributes) {
  return function (dispatch) {
    const body = {
      data: {
        type: ENTITY_TYPE_TASK,
        attributes: taskAttributes,
      },
    };

    return axios.post(NORMALIZATION_URL, body)
      .then(res => res.data.data)
      .catch(error => {
        dispatch(actionFail(null, error.response));
        return Promise.reject();
      });
  };
}

export function createPandocTask(task) {
  return function (dispatch) {
    const body = {
      data: {
        type: ENTITY_TYPE_TASK,
        attributes: task,
      },
    };

    return axios.post(PANDOC_URL, body)
      .then(res => res.data.data)
      .catch(error => {
        dispatch(actionFail(null, error.response));
        return Promise.reject();
      });
  };
}

export function stopTask(taskId) {
  return function (dispatch) {
    const body = {
      data: {
        type: ENTITY_TYPE_TASK,
        id: taskId,
        attributes: {
          status: TASK_STATUS_INTERRUPTED,
        },
      },
    };

    return axios.patch(`${TASKS_URL}/${taskId}`, body)
      .then(res => res.data.data)
      .catch(error => {
        dispatch(actionFail(null, error.response));
        return Promise.reject();
      });
  };
}

export function fetchConversionTypes() {
  return function (dispatch) {
    // TODO: remove fetchOxygenConversionTypes when oxygen validation will be another tab
    return Promise.all([
      fetchXSLTConversionTypes(), fetchPythonConversionTypes(), fetchOxygenConversionTypes(),
    ])
      .then(res => {
        if (res[0].length === 0) {
          // dispatch(showErrorSnackbar('Can not load xslt conversions'));
        }

        if (res[1].length === 0) {
          // dispatch(showErrorSnackbar('Can not load python conversions'));
        }

        return Promise.resolve(
          res.flat().filter(converter => converter.name !== TASK_CONVERTER_NORMALIZATION),
        );
      })
      .catch(error => {
        dispatch(actionFail(null, error.response));
      });
  };
}

export function fetchConversionTags(engine, converter) {
  return function (dispatch) {
    // TODO: remove when oxygen validation will be another tab
    if (engine === TASK_ENGINE_OXYGEN) {
      return new Promise(resolve => {
        const oxygenTags = new Array(
          new ConversionTagEntity('default', '2021-07-07 13:23:25+00:00'),
        );

        resolve(oxygenTags);
      });
    }

    const xsltParser = filename => filename.substring(8, filename.length - 4);
    const pythonParser = filename => filename.substring(18, filename.length - 7);

    let url = XSLT_CONVERSION_TYPES_API_URL;
    let parser = xsltParser;
    if (engine === TASK_ENGINE_PYTHON) {
      url = PYTHON_CONVERSION_TYPES_API_URL;
      parser = pythonParser;
    }

    return axios.get(`${url}/${converter}`)
      .then(res => res.data.data.map(obj => parseConversionTagEntity(parser, obj)))
      .catch(error => {
        dispatch(actionFail(null, error.response));
      });
  };
}

function fetchXSLTConversionTypes() {
  return axios.get(XSLT_CONVERSION_TYPES_API_URL)
    .then(res => res.data.data.map(obj => parseConversionTypeEntity(TASK_ENGINE_XSLT, obj)))
    .catch(() => []);
}

function fetchPythonConversionTypes() {
  return axios.get(PYTHON_CONVERSION_TYPES_API_URL)
    .then(res => res.data.data.map(obj => parseConversionTypeEntity(TASK_ENGINE_PYTHON, obj)))
    .catch(() => []);
}

// TODO: update when oxygen validation will be another tab
function fetchOxygenConversionTypes() {
  return new Promise(resolve => {
    if (ENABLE_OXYGEN_CONVERSIONS) {
      const oxygenTypes = [
        new ConversionTypeEntity(TASK_ENGINE_OXYGEN, 'oxygen-completeness-check'),
        new ConversionTypeEntity(TASK_ENGINE_OXYGEN, 'oxygen-html2dita'),
        new ConversionTypeEntity(TASK_ENGINE_OXYGEN, 'oxygen-word2dita'),
        new ConversionTypeEntity(TASK_ENGINE_OXYGEN, 'oxygen-excel2dita'),
        new ConversionTypeEntity(TASK_ENGINE_OXYGEN, 'oxygen-markdown2dita'),
        new ConversionTypeEntity(TASK_ENGINE_OXYGEN, 'oxygen-word2xhtml'),
      ];

      resolve(oxygenTypes);
    } else {
      resolve([]);
    }
  });
}

export function getTask(id) {
  return axios.get(`${TASKS_URL}/${id}`)
    .then(res => {
      if (res.status === 200 && res.data.data.type === ENTITY_TYPE_TASK) {
        return Promise.resolve(parseTaskEntity(res.data.data));
      }

      return Promise.resolve(null);
    })
    .catch(() => Promise.resolve(null));
}

export function getTaskState(id) {
  return axios.get(`${TASKS_URL}/${id}/state`)
    .then(res => {
      if (res.status === 200 && res.data.data.type === ENTITY_TYPE_TASK_STATE) {
        return Promise.resolve(parseTaskStateEntity(res.data.data));
      }

      return Promise.resolve(null);
    })
    .catch(() => Promise.resolve(null));
}

export function getTaskProgress(id) {
  return axios.get(`${TASKS_URL}/${id}/progress`)
    .then(res => {
      if (res.status === 200 && res.data && res.data.data.type === ENTITY_TYPE_PROGRESS) {
        return Promise.resolve(parseProgressEntity(res.data.data));
      }

      return Promise.resolve(null);
    })
    .catch(() => Promise.resolve(null));
}

export function fetchIxiasoftProducts(url, userId, password) {
  return function (dispatch) {
    const queryParams = `?url=${url}&user_id=${userId}&password=${password}`;

    return axios.get(`${TASKS_API_URL}/ixiasoft/products${encodeURI(queryParams)}`)
      .then(res => res.data.data.map(parseIxiasoftDrmOjectEntity))
      .catch(error => {
        dispatch(actionFail(null, error.response));

        return Promise.reject(error.response);
      });
  };
}

export function fetchIxiasoftUsers(url, userId, password) {
  return function (dispatch) {
    const queryParams = `?url=${url}&user_id=${userId}&password=${password}`;

    return axios.get(`${TASKS_API_URL}/ixiasoft/users${encodeURI(queryParams)}`)
      .then(res => res.data.data.map(parseIxiasoftUserEntity))
      .catch(error => {
        dispatch(actionFail(null, error.response));

        return Promise.reject(error.response);
      });
  };
}

export function fetchIxiasoftStatuses() {
  return function (dispatch) {
    return axios.get(`${TASKS_API_URL}/ixiasoft/statuses`)
      .then(res => res.data.data.map(parseIxiasoftStatusEntity))
      .catch(error => {
        dispatch(actionFail(null, error.response));

        return Promise.reject(error.response);
      });
  };
}

export function fetchIxiasoftObjectTypes() {
  return function (dispatch) {
    return axios.get(`${TASKS_API_URL}/ixiasoft/object_types`)
      .then(res => res.data.data.map(parseIxiasoftObjectTypeEntity))
      .catch(error => {
        dispatch(actionFail(null, error.response));

        return Promise.reject(error.response);
      });
  };
}

export function createIxiasoftImportTask(taskAttributes) {
  return function (dispatch) {
    const body = {
      data: {
        type: ENTITY_TYPE_TASK,
        attributes: taskAttributes,
      },
    };

    return axios.post(IXIASOFT_IMPORT_URL, body)
      .then(res => res.data.data)
      .catch(error => {
        dispatch(actionFail(null, error.response));
        return Promise.reject();
      });
  };
}

export function createIxiasoftExportTask(taskAttributes) {
  return function (dispatch) {
    const body = {
      data: {
        type: ENTITY_TYPE_TASK,
        attributes: taskAttributes,
      },
    };

    return axios.post(IXIASOFT_EXPORT_URL, body)
      .then(res => res.data.data)
      .catch(error => {
        dispatch(actionFail(null, error.response));
        return Promise.reject();
      });
  };
}

export function fetchIxiasoftPing(url, userId, password, checkTemplates = false) {
  const queryParams = `?url=${url}&user_id=${userId}&password=${password}&check_templates=${checkTemplates}`;

  return axios.get(`${TASKS_API_URL}/ixiasoft/ping${encodeURI(queryParams)}`)
    .then(() => Promise.resolve(true))
    .catch(err => {
      const message = err.response.data.data.error;

      return Promise.resolve(message);
    });
}

export function fetchPaligoPublishSettings(serverUrl, apiKey, email) {
  return function (dispatch) {
    const queryParams = `?server_url=${serverUrl}&api_key=${apiKey}&email=${email}`;

    return axios.get(`${TASKS_API_URL}/paligo/publish_settings${encodeURI(queryParams)}`)
      .then(res => res.data.data.items.map(parsePaligoPublishSettingEntity))
      .catch(error => {
        dispatch(actionFail(null, error.response));

        return Promise.reject(error.response);
      });
  };
}

export function fetchPaligoShowDocument(serverUrl, apiKey, email, id) {
  return function (dispatch) {
    const queryParams = `?server_url=${serverUrl}&api_key=${apiKey}&email=${email}`;

    return axios.get(`${TASKS_API_URL}/paligo/documents/${id}${encodeURI(queryParams)}`)
      .then(res => parsePaligoDocumentEntity(res.data.data))
      .catch(error => {
        dispatch(actionFail(null, error.response));

        return Promise.reject(error.response);
      });
  };
}

export function createPaligoPublishTask(taskAttributes) {
  return function (dispatch) {
    const body = {
      data: {
        type: ENTITY_TYPE_TASK,
        attributes: taskAttributes,
      },
    };

    return axios.post(PALIGO_PUBLISH_URL, body)
      .then(res => res.data.data)
      .catch(error => {
        dispatch(actionFail(null, error.response));
        return Promise.reject();
      });
  };
}

export function createPaligoImportTask(taskAttributes) {
  return function (dispatch) {
    const body = {
      data: {
        type: ENTITY_TYPE_TASK,
        attributes: taskAttributes,
      },
    };

    return axios.post(PALIGO_IMPORT_URL, body)
      .then(res => res.data.data)
      .catch(error => {
        dispatch(actionFail(null, error.response));
        return Promise.reject();
      });
  };
}

export function fetchPaligoPing(serverUrl, apiKey, email) {
  const queryParams = `?server_url=${serverUrl}&api_key=${apiKey}&email=${email}`;

  return axios.get(`${TASKS_API_URL}/paligo/ping${encodeURI(queryParams)}`)
    .then(() => Promise.resolve(true))
    .catch(() => Promise.resolve(false));
}

export function createSdlExportTask(taskAttributes) {
  return function (dispatch) {
    const body = {
      data: {
        type: ENTITY_TYPE_TASK,
        attributes: taskAttributes,
      },
    };

    return axios.post(SDL_EXPORT_URL, body)
      .then(res => res.data.data)
      .catch(error => {
        dispatch(actionFail(null, error.response));
        return Promise.reject();
      });
  };
}

export function createSdlImportTask(taskAttributes) {
  return function (dispatch) {
    const body = {
      data: {
        type: ENTITY_TYPE_TASK,
        attributes: taskAttributes,
      },
    };

    return axios.post(SDL_IMPORT_URL, body)
      .then(res => res.data.data)
      .catch(error => {
        dispatch(actionFail(null, error.response));
        return Promise.reject();
      });
  };
}

export function createKionSdlExportTask(taskAttributes) {
  return function (dispatch) {
    const body = {
      data: {
        type: ENTITY_TYPE_TASK,
        attributes: taskAttributes,
      },
    };

    return axios.post(KION_SDL_EXPORT_URL, body)
      .then(res => res.data.data)
      .catch(error => {
        dispatch(actionFail(null, error.response));
        return Promise.reject();
      });
  };
}

export function createKionSdlImportTask(taskAttributes) {
  return function (dispatch) {
    const body = {
      data: {
        type: ENTITY_TYPE_TASK,
        attributes: taskAttributes,
      },
    };

    return axios.post(KION_SDL_IMPORT_URL, body)
      .then(res => res.data.data)
      .catch(error => {
        dispatch(actionFail(null, error.response));
        return Promise.reject();
      });
  };
}

export function fetchSdlFolderLocation(server, username, password, domain, id) {
  return function (dispatch) {
    let queryParams = `?server=${server}&username=${encodeURIComponent(username)}&password=${encodeURIComponent(password)}`;
    if (domain) queryParams += `&domain=${domain}`;

    return axios.get(`${TASKS_API_URL}/sdl/folders/${id}/location${encodeURI(queryParams)}`)
      .then(res => res.data.data.attributes.value)
      .catch(error => {
        dispatch(actionFail(null, error.response));

        return Promise.reject(error.response);
      });
  };
}

export function fetchSdlFolders(server, username, password, domain, folderRef = '') {
  return function (dispatch) {
    dispatch({ type: FETCH_SDL_FOLDERS_START });
    let queryParams = `?server=${server}&username=${encodeURIComponent(username)}&password=${encodeURIComponent(password)}`;
    if (domain) queryParams += `&domain=${domain}`;

    let url = SDL_FOLDER_URL;
    if (folderRef !== '') url += `/${folderRef}/folders`;

    return axios.get(`${url}${encodeURI(queryParams)}`)
      .then(res => {
        const payload = res.data.data;
        dispatch({ type: FETCH_SDL_FOLDERS_SUCCESS, payload });
      })
      .catch(error => {
        dispatch(actionFail(FETCH_SDL_FOLDERS_FAIL, error.response));
      });
  };
}

export function fetchSdlPing(server, username, password, domain) {
  let queryParams = `?server=${server}&username=${encodeURIComponent(username)}&password=${encodeURIComponent(password)}`;

  if (domain) queryParams += `&domain=${domain}`;

  return axios.get(`${TASKS_API_URL}/sdl/ping${encodeURI(queryParams)}`)
    .then(() => Promise.resolve(true))
    .catch(() => Promise.resolve(false));
}

export function createFluidTopicsImportTask(taskAttributes) {
  return function (dispatch) {
    const body = {
      data: {
        type: ENTITY_TYPE_TASK,
        attributes: taskAttributes,
      },
    };

    return axios.post(FLUID_TOPICS_PUBLISH_URL, body)
      .then(res => res.data.data)
      .catch(error => {
        dispatch(actionFail(null, error.response));
        return Promise.reject();
      });
  };
}

export function fetchFluidTopicsPing(baseUrl, apiKey) {
  const queryParams = `?base_url=${baseUrl}&api_key=${apiKey}`;

  return axios.get(`${TASKS_API_URL}/fluid-topics/ping${encodeURI(queryParams)}`)
    .then(() => Promise.resolve(true))
    .catch(() => Promise.resolve(false));
}

export function createGitHubUploadTask(taskAttributes) {
  return function (dispatch) {
    const body = {
      data: {
        type: ENTITY_TYPE_TASK,
        attributes: taskAttributes,
      },
    };

    return axios.post(GITHUB_UPLOAD_URL, body)
      .then(res => res.data.data)
      .catch(error => {
        dispatch(actionFail(null, error.response));
        return Promise.reject();
      });
  };
}

export function createGitHubDownloadTask(taskAttributes) {
  return function (dispatch) {
    const body = {
      data: {
        type: ENTITY_TYPE_TASK,
        attributes: taskAttributes,
      },
    };

    return axios.post(GITHUB_DOWNLOAD_URL, body)
      .then(res => res.data.data)
      .catch(error => {
        dispatch(actionFail(null, error.response));
        return Promise.reject();
      });
  };
}

export function fetchGitHubPing(organizationName, accessKey) {
  const queryParams = `?organization_name=${organizationName}&access_key=${accessKey}`;

  return axios.get(`${TASKS_API_URL}/github/ping${encodeURI(queryParams)}`)
    .then(() => Promise.resolve(true))
    .catch(() => Promise.resolve(false));
}

export function createZoominExportTask(taskAttributes) {
  return function (dispatch) {
    const body = {
      data: {
        type: ENTITY_TYPE_TASK,
        attributes: taskAttributes,
      },
    };

    return axios.post(ZOOMIN_EXPORT_URL, body)
      .then(res => res.data.data)
      .catch(error => {
        dispatch(actionFail(null, error.response));
        return Promise.reject();
      });
  };
}

export function createZoominUploadTask(taskAttributes) {
  return function (dispatch) {
    const body = {
      data: {
        type: ENTITY_TYPE_TASK,
        attributes: taskAttributes,
      },
    };

    return axios.post(ZOOMIN_UPLOAD_URL, body)
      .then(res => res.data.data)
      .catch(error => {
        dispatch(actionFail(null, error.response));
        return Promise.reject();
      });
  };
}

export function fetchGitHubRepositories(organizationName, accessKey) {
  return function (dispatch) {
    dispatch({ type: FETCH_GITHUB_REPORISORIES_START });

    const queryParams = `?organization_name=${organizationName}&access_key=${accessKey}`;

    return axios.get(`${GITHUB_REPOSITORIES_URL}${encodeURI(queryParams)}`)
      .then(res => {
        const payload = res.data.data;
        dispatch({ type: FETCH_GITHUB_REPORISORIES_SUCCESS, payload });
      })
      .catch(error => {
        dispatch(actionFail(FETCH_GITHUB_REPORISORIES_FAIL, error.response));
      });
  };
}

export function fetchGitHubBranches(repositoryName, organizationName, accessKey) {
  return function (dispatch) {
    dispatch({ type: FETCH_GITHUB_BRANCHES_START });

    const queryParams = `?organization_name=${organizationName}&access_key=${accessKey}`;

    return axios.get(`${GITHUB_REPOSITORIES_URL}/${repositoryName}/branch${encodeURI(queryParams)}`)
      .then(res => {
        const payload = res.data.data;

        dispatch({ type: FETCH_GITHUB_BRANCHES_SUCCESS, payload });
      })
      .catch(() => {
        dispatch({ type: FETCH_GITHUB_BRANCHES_FAIL });
      });
  };
}
